<template>
  <layout name="OrganizLayout">
    <div class="Home">
      <!-- HERO -->
      <page-header/>

      <section class="uk-section uk-padding-remove">
        <div class="uk-container">
          <div class="uk-text-center uk-margin">
            <ul class="uk-breadcrumb uk-flex-center uk-margin-remove">
              <li>
                <a>{{ $t("organization.questionList.home") }}</a>
              </li>
              <li>
                <span>{{ $t("organization.questionList.questList") }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="uk-container uk-margin-top">
          <div class="uk-card uk-card-default uk-width-1-1 uk-margin-remove-left">
            <table class="uk-table uk-table-responsive uk-table-small">
              <tbody>
                <tr>
                  <th class="uk-text-bold borderFull"
                      style="border-top: 3px solid rgb(1, 176, 255) !important;">
                    {{ $t("organization.questionList.eCate") }}
                  </th>
                  <th style="border-top: 3px solid rgb(1, 176, 255) !important;"
                      class="border-top-bottom uk-text-left" colspan="3">
                    <div class="uk-flex" uk-grid>
                      <v-select @input="categoryChange('exam', 'main')"
                                :placeholder="$t('orgaExam.questionManager.main')"
                                v-model="search.cate1" label="name" :reduce="(option) => option.id"
                                :options="mainCategory.main" class="uk-width-1-3">
                      </v-select>
                      <v-select @input="categoryChange('exam', 'middle')"
                                v-model="search.cate2" label="name" :reduce="(option) => option.id"
                                :placeholder="$t('orgaExam.questionManager.mid')"
                                :options="mainCategory.middle" class="uk-width-1-3">
                      </v-select>
                      <v-select @input="categoryChange('exam', 'low')"
                                v-model="search.cate3" label="name" :reduce="(option) => option.id"
                                :placeholder="$t('orgaExam.questionManager.low')"
                                :options="mainCategory.low" class="uk-width-1-3">
                      </v-select>
                    </div>
  <!--                    <select-->
  <!--                        class="uk-select uk-form-width-medium uk-form-small"-->
  <!--                        @change="categoryChange('exam', 'main')"-->
  <!--                        v-model="search.cate1">-->
  <!--                      <option value="">{{ $t("organization.questionList.main") }}</option>-->
  <!--                      <template v-for="(item, index) in mainCategory.main">-->
  <!--                        <option :value="item.id" :key="index">{{ item.name }}</option>-->
  <!--                      </template>-->
  <!--                    </select>-->
  <!--                    <select-->
  <!--                        @change="categoryChange('exam', 'middle')"-->
  <!--                        class="uk-select uk-form-width-medium uk-form-small uk-margin-left"-->
  <!--                        v-model="search.cate2">-->
  <!--                      <option value="">{{ $t("organization.questionList.mid") }}</option>-->
  <!--                      <template v-for="(item, index) in mainCategory.middle">-->
  <!--                        <option :value="item.id" :key="index">{{ item.name }}</option>-->
  <!--                      </template>-->
  <!--                    </select>-->
  <!--                    <select-->
  <!--                        @change="categoryChange('exam', 'low')"-->
  <!--                        class="uk-select uk-form-width-medium uk-form-small  uk-margin-left"-->
  <!--                        v-model="search.cate3">-->
  <!--                      <option value="">{{ $t("organization.questionList.low") }}</option>-->
  <!--                      <template v-for="(item, index) in mainCategory.low">-->
  <!--                        <option :value="item.id" :key="index">{{ item.name }}</option>-->
  <!--                      </template>-->
  <!--                    </select>-->
                  </th>
                </tr>
                <tr>
                  <th class="borderFull uk-text-bold">
                    <span class="input_require"> {{ $t("organization.questionList.quesType") }}</span>
                  </th>
                  <th colspan="3" class="border-top-bottom uk-text-left">
                    <input
                        class="uk-radio uk-margin-small-left"
                        type="radio"
                        value="1"
                        v-model="search.qtype"
                    /><span class="uk-margin-small-left">  {{ $t("organization.questionList.choiceA") }}</span>
                    <input
                        class="uk-radio uk-margin-small-left"
                        type="radio"
                        value="3"
                        v-model="search.qtype"
                    /> <span class="uk-margin-small-left"> {{ $t("organization.questionList.choiceR") }}</span>
                    <input
                        class="uk-radio uk-margin-small-left"
                        type="radio"
                        value="2"
                        v-model="search.qtype"
                    /><span class="uk-margin-small-left"> {{ $t("organization.questionList.sAnswer") }}</span>
                    <input
                        class="uk-radio uk-margin-small-left"
                        type="radio"
                        value="5"
                        v-model="search.qtype"
                    /><span class="uk-margin-small-left"> {{ $t("organization.questionList.imageUpload") }}</span>
                  </th>
                </tr>
                <tr>
                  <th class="borderFull uk-text-bold"> {{ $t("organization.questionList.ques") }}</th>
                  <th class="border-top-bottom" colspan="3">
                    <input
                        type="text"
                        :placeholder="$t('wHere')"
                        class="uk-input uk-form-small"
                        v-model="search.question"/>
                  </th>
                </tr>
                <tr>
                  <th class="borderFull uk-text-bold ">
                    {{ $t("organization.questionList.quesLevel") }}
                  </th>
                  <th class="border-top-bottom uk-text-left">
                    <input
                        name="difficultyLevel"
                        type="radio"
                        value="1"
                        class="uk-radio uk-margin-small-left"
                        v-model="search.qlevel"
                    /> <span
                      class="uk-margin-small-left"> {{ $t("organization.questionList.low3") }}</span>
                    <input
                        name="difficultyLevel"
                        type="radio"
                        value="2"
                        class="uk-radio uk-margin-left"
                        v-model="search.qlevel"
                    /> <span
                      class="uk-margin-small-left"> {{ $t("organization.questionList.low2") }}</span>
                    <input
                        name="difficultyLevel"
                        type="radio"
                        value="3"
                        class="uk-radio uk-margin-left"
                        v-model="search.qlevel"
                    /> <span class="uk-margin-small-left"> {{ $t("organization.questionList.medium") }}</span>
                    <input
                        name="difficultyLevel"
                        type="radio"
                        value="4"
                        class="uk-radio uk-margin-left"
                        v-model="search.qlevel"
                    /> <span
                      class="uk-margin-small-left"> {{ $t("organization.questionList.high") }}</span>
                    <input
                        name="difficultyLevel"
                        type="radio"
                        value="5"
                        class="uk-radio uk-margin-left"
                        v-model="search.qlevel"
                    /> <span
                      class="uk-margin-small-left"> {{ $t("organization.questionList.high2") }}</span>
                  </th>
                  <th style="color:#000">{{$t("qno")}}
                  </th>
                  <th>
                    <input
                        type="number"
                        :placeholder="$t('wHere')"
                        class="uk-input uk-form-small"
                        v-model="search.controlNo"/>
                  </th>
                </tr>
                <tr>
                  <th  class="borderFull uk-text-bold uk-text-capitalize">
                    {{ $t("organization.questionList.keyword") }}
                  </th>
                  <th colspan="3">
                    <input
                        type="text"
                        :placeholder="$t('keyWhere')"
                        class="uk-input uk-form-small"
                        v-model="search.keyword"/>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="uk-width-1-1 uk-flex uk-flex-right">
            <button type="button" class=" uk-flex uk-flex-right qbtn_d uk-button"
                    style="background-color: white; color: rgb(1, 176, 255); border-color: rgb(1, 176, 255)"
                    @click="defaultSetting">
              {{ $t("organization.questionList.dSet") }}
            </button>
            <button type="button" class="qbtn_d uk-button uk-margin-left"
                    style="background-color: rgb(1, 176, 255);"
                    @click="searchFilter()"> {{ $t("organization.questionList.search") }}
            </button>
          </div>
          <div class="uk-card uk-card-default uk-card-small uk-margin-top">
            <header class="uk-card-header" style="border-top: 3px solid rgb(1, 176, 255) !important;">
              <div class="uk-grid-small uk-flex-middle" uk-grid>
                <div class="uk-width-1-1 uk-width-expand@s uk-flex uk-flex-center uk-flex-left@s uk-text-small">
                  <button
                      class="uk-button uk-button-small uk-button-danger"
                      @click="beforeReAll()"> {{ $t("organization.questionList.dAll") }}
                  </button>
                </div>
                <div class="uk-width-1-1 uk-width-auto@s uk-flex uk-flex-right uk-flex-middle">
                  <router-link
                      class="uk-button uk-button-default uk-button-small uk-hidden@m"
                      style="color: rgb(1, 176, 255); border-color: rgb(1, 176, 255)"
                      title="Question register"
                      tag="a"
                      :to="{name: 'OrganizationQuestion',
                                          params:{questionId: 0}}">
                                          <span class="uk-margin-xsmall-right" uk-icon="icon: plus;
                                          ratio: .75;"></span>
                    {{ $t("organization.questionList.cQues") }}
                  </router-link>
                  <div class="tm-change-view uk-margin-small-left">
                    <ul class="uk-subnav uk-iconnav js-change-view">
                      <li>
                        <button type="button" class="uk-button uk-button-default uk-button-small"
                                style="color: rgb(1, 176, 255); border-color: rgb(1, 176, 255)"
                                @click="createQuestion()">
                                                      <span class="uk-margin-xsmall-right" uk-icon="icon: plus;
                                                      ratio: .95;"></span>
                          {{ $t("organization.questionList.cQues") }}
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </header>
            <div class="uk-card-body uk-padding-remove">
              <table
                  v-if="Questions.length > 0"
                  class="uk-table uk-table-responsive uk-table-striped">
                <thead>
                <tr>
                  <th>
                    <input type="checkbox" class="uk-checkbox" style="width: 18px; height: 18px;"
                           v-model="selectAll" @click="select"/>
                  </th>
                  <th @click="orderTable('id')">{{ $t("organization.questionList.id") }}
                    <img src="../../assets/icons/asc.png" alt="" width="16" height="16"
                         v-if="tableorder.sort == 'id' && tableorder.ascordesc == false"/>
                    <img src="../../assets/icons/desc.png" alt="" width="16" height="16"
                         v-else-if="tableorder.sort == 'id' && tableorder.ascordesc == true"/>
                    <img src="../../assets/icons/sort1.png" alt="" width="16" height="16" v-else/>
                  </th>
                  <th>{{ $t("organization.questionList.eCat") }}</th>
                  <th @click="orderTable('controlNo')">
                    {{ $t("qno") }}
                    <img src="../../assets/icons/asc.png" alt="" width="16" height="16"
                         v-if="tableorder.sort == 'controlNo' && tableorder.ascordesc == false"/>
                    <img src="../../assets/icons/desc.png" alt="" width="16" height="16"
                         v-else-if="tableorder.sort == 'controlNo' && tableorder.ascordesc == true"/>
                    <img src="../../assets/icons/sort1.png" alt="" width="16" height="16" v-else/>
                  </th>
                  <!-- <th>Purchased</th> -->
                  <th @click="orderTable('questionType')">
                    {{ $t("organization.questionList.type") }}
                    <img src="../../assets/icons/asc.png" alt="" width="16" height="16"
                         v-if="tableorder.sort == 'questionType' && tableorder.ascordesc == false"/>
                    <img src="../../assets/icons/desc.png" alt="" width="16" height="16"
                         v-else-if="tableorder.sort == 'questionType' && tableorder.ascordesc == true"/>
                    <img src="../../assets/icons/sort1.png" alt="" width="16" height="16" v-else/>
                  </th>
                  <th @click="orderTable('question')">{{ $t("organization.questionList.ques") }}
                    <img src="../../assets/icons/asc.png" alt="" width="16" height="16"
                         v-if="tableorder.sort == 'question' && tableorder.ascordesc == false"/>
                    <img src="../../assets/icons/desc.png" alt="" width="16" height="16"
                         v-else-if="tableorder.sort == 'question' && tableorder.ascordesc == true"/>
                    <img src="../../assets/icons/sort1.png" alt="" width="16" height="16" v-else/>
                  </th>
                  <th>{{ $t("organization.questionList.regUser") }}</th>
                  <th @click="orderTable('createdAt')">
                    {{ $t("organization.questionList.regDate") }}
                    <img src="../../assets/icons/asc.png" alt="" width="16" height="16"
                         v-if="tableorder.sort == 'createdAt' && tableorder.ascordesc == false"/>
                    <img src="../../assets/icons/desc.png" alt="" width="16" height="16"
                         v-else-if="tableorder.sort == 'createdAt' && tableorder.ascordesc == true"/>
                    <img src="../../assets/icons/sort1.png" alt="" width="16" height="16" v-else/>
                  </th>
                  <th style="width: 40px;">
                    {{ $t("action") }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item,index) in Questions" :item="item" :key="index">
                  <td>
                    <input
                        v-if="item.ownerId == currentUser.id || currentUser.authorities[0].authority == 'ROLE_ADMIN'"
                        type="checkbox"
                        class="uk-checkbox"
                        v-model="selected"
                        :value="item.id"/>
                  </td>
                  <td>
                    {{ item.id }}
                  </td>
                  <td style="max-width: 80px">{{ item.cate1 }} <span class="uk-icon"
                                                                     uk-icon="icon: chevron-right; ratio: .75;"></span><br>
                    {{ item.cate2 }} <span class="uk-icon"
                                           uk-icon="icon: chevron-right; ratio: .75;"></span><br>
                    {{ item.cate3 }}
                  </td>
                  <td>
                    {{ item.controlNo }}
                  </td>
                  <td>
                    <p v-if="item.question_type == '1'">
                      {{ $t("organization.questionList.choiceA") }}
                    </p>
                    <p v-else-if="item.question_type == '3'">
                      {{ $t("organization.questionList.choiceR") }}
                    </p>
                    <p v-else-if="item.question_type == '2'">
                      {{ $t("organization.questionList.sAnswer") }}
                    </p>
                    <p v-else-if="item.question_type == '4'">
                      {{ $t("organization.questionList.drawing") }}
                    </p>
                    <p v-else-if="item.question_type == '6'">
                      {{ $t("organization.questionList.audio") }}
                    </p>
                    <p v-else>
                      {{ $t("organization.questionList.imageUpload") }}
                    </p>
                  </td>
                  <td v-html="item.question.length > 50 ? item.question.substring(0, 50) + '...' : item.question"
                      class="uk-text-small" style="max-width: 320px;">
                  </td>
                  <td>{{ item.userName }}</td>
                  <td>{{ item.updatedAt|moment }}</td>
                  <td>
                    <div>
                      <a title="Question detail" v-on:click="questionDetails(item)"><span
                          uk-icon="icon: more; ratio: 1.5;"></span>
                      </a>
                      <a title="Question modify" @click="updateModal(item)"
                         v-if="item.ownerId == currentUser.id || currentUser.authorities[0].authority == 'ROLE_ADMIN'">
                        <span uk-icon="icon: file-edit; ratio: 1.5;"></span>
                      </a>
                      <a title="Question delete" class="uk-text-danger"
                         @click="removeTagId(item)"
                         v-if="item.ownerId == currentUser.id || currentUser.authorities[0].authority == 'ROLE_ADMIN'">
                        <span uk-icon="icon: trash; ratio: 1.5;"></span>
                      </a>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
              <div v-else class="uk-alert-primary" uk-alert>
                <p class="uk-text-center">Empty.</p>
              </div>
            </div>

            <div class="uk-card-footer uk-text-center">
              <div class="uk-flex uk-flex-center">
                <v-pagination
                    v-model="currentPage"
                    :page-count="pages"
                    :classes="uikitClasses"
                    :labels="Labels"
                    @change="onChange"
                ></v-pagination>
              </div>
            </div>
          </div>
        </div>
        <div id="deleteQuestion" uk-modal>
          <div class="uk-modal-dialog">
            <button class="uk-modal-close-default" uk-close></button>
            <div class="uk-modal-header">
              <h2 class="uk-modal-title uk-text-center"
                  style="font-size: 21px; font-weight: 700;">{{ $t('deleteQ') }}</h2>
            </div>
            <div class="uk-modal-body uk-text-center">
              <h3>{{ $t('deleteQuestion') }}</h3>
            </div>
            <div class="uk-modal-footer uk-text-right">
              <button type="button" class="uk-button uk-button-default
                              uk-button-small uk-modal-close">
                {{ $t('close') }}
              </button>
              <button type="button" class="uk-button uk-button-primary
                        uk-button-small uk-margin-left" @click="removedQuestion()">
                {{ $t('delete') }}
              </button>
            </div>
          </div>
        </div>
        <div id="deleteAll" uk-modal>
          <div class="uk-modal-dialog">
            <button class="uk-modal-close-default" uk-close></button>
            <div class="uk-modal-header">
              <h2 class="uk-modal-title uk-text-center"
                  style="font-size: 21px; font-weight: 700;">{{ $t('deleteQ') }}</h2>
            </div>
            <div class="uk-modal-body uk-text-center">
              <h3>{{ $t('deleteQuestion') }}</h3>
            </div>
            <div class="uk-modal-footer uk-text-right">
              <button type="button" class="uk-button uk-button-default
                              uk-button-small uk-modal-close">
                {{ $t('close') }}
              </button>
              <button type="button" class="uk-button uk-button-primary
                              uk-button-small uk-margin-left" @click="removeSelected()">
                {{ $t('delete') }}
              </button>
            </div>
          </div>
        </div>
      </section>

      <page-footer/>

    </div>
  </layout>
</template>


<script scoped>
import Layout from "../../layouts/Layout"
import moment from "moment"
import vPagination from "@/components/globals/vue-plain-pagination"
import {mapGetters} from "vuex"
import QuestionService from "@/services/QuestionService"
import CategoryDataService from "@/services/CategoryDataService"
// eslint-disable-next-line no-unused-vars
import UIkit from 'uikit'
import PageHeader from '@/components/Organizcmp/OrganizHeader.vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

const PageFooter = () => import("../Organizcmp/OrganizFooter.vue")

export default {
  name: "Home",
  components: {
    Layout,
    vPagination,
    PageHeader,
    vSelect,
    PageFooter
  },
  data() {
    return {
      organization: "",
      questionPrice: "",
      qInputPrice: 0,
      purchase: "true",
      checked: "",
      questionDetail: {},
      newCount: 0,
      sortselected: "type",
      Questions: [],
      currentPage: 1,
      pages: 0,
      mainCategory: {
        main: [],
        middle: [],
        low: []
      },
      subjectCategory: {
        main: [],
        middle: [],
        low: []
      },
      tableorder: {
        sort: '',
        ascordesc: false
      },
      question: {
        permissions: [],
        questionType: "",
        correctRate: "",
        mainCategory: {
          main: "0",
          middle: "0",
          low: "0"
        },
        bulletedListType: [
          {
            first: "",
            second: "",
            third: "",
            fourth: "",
            fifth: ""
          }
        ],
        point: 0,
        subjectCategory: {
          main: "0",
          middle: "0",
          low: "0"
        },
        questionLevel: "",
        estimatedCorrectRate: "",
        knowledgeLevel: "",
        questionRevevance: [],
        topic: "",
        keywords: [],
        reference: "",
        headerText: "",
        questionData: "",
        presentDatas: [
          {
            fileName: "",
            type: "text",
            file: "",
            text: ""
          }
        ],
        answers: [
          {
            fileName: "",
            type: "text",
            file: "",
            text: "",
            correctAnswer: false,
            optionNumber: ""
          }
        ],
        wrongNotes: [],
        examType: "",
        examPrice: "",
        cloudQType: "",
        permission: []
      },
      search: {
        cate1: "",
        cate2: "",
        cate3: "",
        scate1: "",
        scate2: "",
        scate3: "",
        question: "",
        keyword: "",
        controlNo:"",
        point: "",
        qtype: "",
        qlevel: ""
      },
      uikitClasses: {
        ul: "uk-pagination",
        li: "",
        liActive: "uk-active",
        liDisable: "uk-disabled",
        button: "page-link"
      },
      Labels: {
        first: "<span uk-icon='chevron-double-left'; ratio = '1.2'></span>",
        prev: "<span uk-pagination-previous></span>",
        next: "<span uk-pagination-next></span>",
        last: "<span uk-icon='chevron-double-right'; ratio = '1.2'></span>"
      },
      selected: [],
      selectAll: false,
      updateId: '',
      removeQu: {},
      ascordesc: '',
      // localPath: 'http://localhost:8084/ubtexam/teacher/qbank',
      // localPath2: 'http://localhost:8084/ubtexam/teacher/passageqbank',
      localPath: '/ubtexam/teacher/qbank',
      localPath2: '/ubtexam/teacher/passageqbank',
    };
  },
  filters: {
    moment: function (date) {
      return moment(date).format("YYYY.MM.DD");
    },
  },
  created() {
    this.organization = this.currentUser.organizs[0]
  },
  async mounted() {
    this.loadCates("main", 1, 0);
    this.loadCates("subject", 1, 0);
  },
  methods: {
    async questionDetails(item) {
      this.$setCookie('utoken', this.getToken);
      this.$setCookie('urole', this.currentUser.authorities[0].authority);
      this.$setCookie('uorganizId', this.currentUser.organizs[0].organizId);
      window.open("/ubtexam/teacher/questionDetail?lang=" + this.$i18n.locale + "&questionId=" + item.id, '_blank',
          'width=1440,height=789')
    },
    defaultSetting() {
      window.location.reload(true);
    },
    createQuestion() {
      this.$setCookie('utoken', this.getToken);
      this.$setCookie('urole', this.currentUser.authorities[0].authority);
      this.$setCookie('uorganizId', this.currentUser.organizs[0].organizId);
      window.open(this.localPath)
    },
    updateModal(item) {
      this.updateId = item.id
      this.$setCookie('utoken', this.getToken);
      this.$setCookie('urole', this.currentUser.authorities[0].authority);
      this.$setCookie('uorganizId', this.currentUser.organizs[0].organizId);
      if (item.passage != 1) {
        window.open(this.localPath + "?questionId=" + item.id)
      } else {
        window.open(this.localPath2 + "?questionId=" + item.id)
      }
    },
    async removeTagId(item) {
      this.removeQu = item
      UIkit.modal('#deleteQuestion').show();
    },

    answerTypeChange(type) {
      this.answerType = type;
    },
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let item in this.Questions) {
          this.selected.push(this.Questions[item].id);
        }
      }
    },
    orderTable(order) {
      this.tableorder.sort = order
      this.tableorder.ascordesc = !this.tableorder.ascordesc
      if (this.tableorder.ascordesc) {
        this.ascordesc = 'asc'
      } else {
        this.ascordesc = 'desc'
      }
      this.currentPage = 1;
      this.loaddata(this.currentPage)
    },
    beforeReAll() {
      UIkit.modal('#deleteAll', null).show();
    },
    async removeSelected() {
      try {
        if (this.selected.length != 0) {
          const response = await QuestionService.deleteQuestion(this.selected);
          console.log(response);
          UIkit.modal('#deleteAll', null).hide();
          alert("Successfully deleted the questions");
          this.currentPage = 0;
          this.loaddata(this.currentPage);
        } else {
          alert("you need to choose your questions before !!! ");
        }
      } catch (error) {
        console.log(error);
      }
    },
    async removedQuestion() {
      try {
        let ii = []
        ii.push(this.removeQu.id)
        const response = await QuestionService.deleteQuestion(ii);
        if (response.data.status == 200) {
          UIkit.modal('#deleteQuestion', null).hide();
          alert("Successfully deleted it");
          // window.location.reload()
          this.currentPage = 0;
          this.loaddata(this.currentPage);
        } else {
          alert(response.data);
        }
      } catch (error) {
        console.log(error);
      }
      // }
    },
    async searchFilter() {
      this.loaddata(this.currentPage);
    },
    categoryChange(type, catelevel) {
      if (type == "exam") {
        type = "main";
      } else {
        type = "subject";
      }
      if (catelevel == "main") {
        let parentId = 0;
        if (type == "main") parentId = this.search.cate1;
        else parentId = this.search.scate1;
        this.loadCates(type, 2, parentId);
      }
      if (catelevel == "middle") {
        let parentId = 0;
        if (type == "main") parentId = this.search.cate2;
        else parentId = this.search.scate2;
        this.loadCates(type, 3, parentId);
      }
    },
    async loadCates(type, cateStep, parentId) {
      try {
        const response = await CategoryDataService.getCategoriesStep({
          type: type,
          cateStep: cateStep,
          parentId: parentId
        });
        if (response.data.status === 200) {
          if (type == "main") {
            //Category
            if (cateStep == 1) {
              this.mainCategory.main = response.data.result.cates;
            }
            if (cateStep == 2) {
              this.mainCategory.middle = response.data.result.cates;
            }
            if (cateStep == 3) {
              this.mainCategory.low = response.data.result.cates;
            }
          } else {
            //subject category
            if (cateStep == 1) {
              this.subjectCategory.main = response.data.result.cates;
            }
            if (cateStep == 2) {
              this.subjectCategory.middle = response.data.result.cates;
            }
            if (cateStep == 3) {
              this.subjectCategory.low = response.data.result.cates;
            }
          }
        } else {
          alert("ERROR: " + response.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    searchDate() {
      this.loaddata(this.currentPage);
    },
    async loaddata(page) {
      try {
        if (page > 0) {
          page = page - 1;
        }
        const response = await QuestionService.getQuestions({
          page: page,
          sort: this.sortselected,
          organizId: this.currentUser.organizs[0].organizId,
          question: this.search.question,
          point: this.search.point,
          keyword: this.search.keyword,
          controlNo: this.search.controlNo,
          qtype: this.search.qtype,
          qlevel: this.search.qlevel,
          cate1: this.search.cate1,
          cate2: this.search.cate2,
          cate3: this.search.cate3,
          scate1: this.search.scate1,
          scate2: this.search.scate2,
          scate3: this.search.scate3,
          sortfield: this.tableorder.sort,
          ascordesc: this.ascordesc
        });
        if (response.status == 200) {
          this.Questions = response.data.content;
          this.pages = response.data.totalPages;
          if (!this.Questions.length) {
            this.isLoading = false;
            return;
          }
        }
      } catch (error) {
        console.log(error.response);
        this.error = error.response.data.message;
        this.scrollToTop();
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    onChange: function () {
      // eslint-disable-next-line no-console
      this.loaddata(this.currentPage);
    }
  },
  computed: {
    ...mapGetters(["isLoggedIn", "getToken", "currentUser"])
  }
};
</script>

<style scoped>
.uk-table td {
  font-size: 13px;
}

.cloud h4 {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 1.3em;
  font-weight: 600;
  color: rgb(1, 176, 255);
  padding-left: 15px;
  border-left: 2px solid rgb(1, 176, 255);
}

.qList h3 {
  padding-top: 10px;
  font-size: 1.3em;
  font-weight: 600;
  color: rgb(1, 176, 255);
  padding-bottom: 15px;
  border-bottom: 2px solid rgb(1, 176, 255);
  text-align: center;
}

span >>> p {
  white-space: pre-line;
}

ul {
  padding: 0;
}

ul > li {
  list-style: none;
  display: inline-block;
  padding: 0;
}

.data_text {
  border: 1px solid #000;
  margin-bottom: 15px;
}

.checkSize {
  display: inline-block;
  overflow: hidden;
  margin-top: -4px;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border: 1px solid #cccccc;
  transition: 0.2s ease-in-out;
  transition-property: background-color, border;
}

/* answer style */
.qu_in .v_example tr.on th {
  background: url("../../assets/v_on.png") center 7px no-repeat;
  color: #fff;
}

.qu_in .v_example tr th {
  width: 12%;
  padding: 11px 0;
  background: url("../../assets/v_off.png") center 7px no-repeat;
  vertical-align: text-top;
}


.uk-table td {
  padding: 2px 12px;
  vertical-align: middle;
}

tbody .borderFull {
  border: 1px solid #dee2e6;
  padding: 5px 10px;
  vertical-align: middle;
  text-align: left;
  font-size: 14px;
  color: #000;
}

.uk-button {
  min-width: 100px;
}

tbody .border-top-bottom {
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  font-size: 15px;
}

.uk-table th {
  padding: 10px 5px;
}

.uk-table th:nth-of-type(1) {
  text-align: center;
}

.qbtn_d {
  border: 1px solid #fff;
  display: inline-block;
  padding: 0px 10px;
  color: #fff;
  font-weight: 500;
  border-bottom-left-radius: 1em;
  border-top-right-radius: 1em;
  border-top-left-radius: 1em;
  border-bottom-right-radius: 1em;
  min-width: 30px;
  text-align: center;
  width: auto;
}

.uk-label-warning {
  padding: 6px;
  width: 60px;
}

.uk-modal-dialog {
  border-radius: 15px;
  width: 710px;
}
.vs__clear {
  display: none;
}
</style>
